import React, { Component } from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import Post from "components/shared/Post";
import CTA from "components/shared/CTA";
import Layout from "components/shared/Layout";
import { graphql } from "gatsby";

export default class PostTemplate extends Component {
  checkDateHidden = (string) => {
    return /\/careers\//g.test(string);
  };

  render() {
    let { pageContext } = this.props;
    const { allWpPage, allWpPost, wpPage } = this.props.data;
    const { custom_data, featuredImage, toc } = wpPage;
    return (
      <>
        <Meta
          url={pageContext.canonical}
          defaultTitle={custom_data?.metaTitle}
          defaultDescription={custom_data?.metaDescription}
          defaultDeaturedImage={featuredImage?.node?.localFile?.publicURL}
        />
        <Nav />
        <Layout>
          <Post
            allWpPage={allWpPage}
            allWpPost={allWpPost}
            data={wpPage}
            hideDate
            toc={custom_data?.includeTableOfContents && toc}
            url={pageContext.canonical}
          />
        </Layout>
        <CTA />
        <Footer />
      </>
    );
  }
}

export const pageQuery = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      toc
      title
      content
      slug
      featuredImage {
        node {
          sourceUrl
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 1100
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      related_content {
        wordpressSlug1
        title1
        wordpressSlug2
        title2
        wordpressSlug3
        title3
      }
      custom_data {
        metaTitle
        metaDescription
        includeTableOfContents
      }
      author {
        node {
          name
        }
      }
      date
    }
    allWpPage {
      edges {
        node {
          slug
          title
          link
          featuredImage {
            node {
              sourceUrl
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
    allWpPost {
      edges {
        node {
          slug
          title
          categories {
            nodes {
              slug
              name
            }
          }
          featuredImage {
            node {
              sourceUrl
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;
